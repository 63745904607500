.adsbygoogle {
  display: block;
}

#nonogram {
  margin: 0 auto;
  border-width: 0;

  .r {
    border-right-width: 2px;
  }
  .b,
  .b > td {
    border-bottom-width: 2px;
  }

  .puzzle-cell {
    touch-action: none;
  }

  .puzzle-out {
    border: none;
  }
  .left-numbers {
    padding: 0 5px;
    text-align: right;
    border-right-width: 2px;
  }
  &.solved {
    .puzzle-cell-on {
      animation: colorchange 3s;
      @keyframes colorchange {
        0% {
          background: red;
        }
        14% {
          background: orange;
        }
        28% {
          background: yellow;
        }
        43% {
          background: green;
        }
        57% {
          background: blue;
        }
        71% {
          background: indigo;
        }
        86% {
          background: violet;
        }
        100% {
          background: auto;
        }
      }
      &.puzzle-anim-1 {
        animation-delay: 100ms;
      }
      &.puzzle-anim-2 {
        animation-delay: 200ms;
      }
      &.puzzle-anim-3 {
        animation-delay: 300ms;
      }
      &.puzzle-anim-4 {
        animation-delay: 400ms;
      }
      &.puzzle-anim-5 {
        animation-delay: 500ms;
      }
      &.puzzle-anim-6 {
        animation-delay: 600ms;
      }
      &.puzzle-anim-7 {
        animation-delay: 700ms;
      }
      &.puzzle-anim-8 {
        animation-delay: 800ms;
      }
      &.puzzle-anim-9 {
        animation-delay: 900ms;
      }
      &.puzzle-anim-10 {
        animation-delay: 1000ms;
      }
      &.puzzle-anim-11 {
        animation-delay: 1100ms;
      }
      &.puzzle-anim-12 {
        animation-delay: 1200ms;
      }
      &.puzzle-anim-13 {
        animation-delay: 1300ms;
      }
      &.puzzle-anim-14 {
        animation-delay: 1400ms;
      }
      &.puzzle-anim-15 {
        animation-delay: 1500ms;
      }
      &.puzzle-anim-16 {
        animation-delay: 1600ms;
      }
      &.puzzle-anim-17 {
        animation-delay: 1700ms;
      }
      &.puzzle-anim-18 {
        animation-delay: 1800ms;
      }
      &.puzzle-anim-19 {
        animation-delay: 1900ms;
      }
      &.puzzle-anim-20 {
        animation-delay: 2000ms;
      }
      &.puzzle-anim-21 {
        animation-delay: 2100ms;
      }
      &.puzzle-anim-22 {
        animation-delay: 2200ms;
      }
      &.puzzle-anim-23 {
        animation-delay: 2300ms;
      }
      &.puzzle-anim-24 {
        animation-delay: 2400ms;
      }
      &.puzzle-anim-25 {
        animation-delay: 2500ms;
      }
      &.puzzle-anim-26 {
        animation-delay: 2600ms;
      }
      &.puzzle-anim-27 {
        animation-delay: 2700ms;
      }
      &.puzzle-anim-28 {
        animation-delay: 2800ms;
      }
      &.puzzle-anim-29 {
        animation-delay: 2900ms;
      }
      &.puzzle-anim-30 {
        animation-delay: 3000ms;
      }
      &.puzzle-anim-31 {
        animation-delay: 3100ms;
      }
      &.puzzle-anim-32 {
        animation-delay: 3200ms;
      }
      &.puzzle-anim-33 {
        animation-delay: 3300ms;
      }
      &.puzzle-anim-34 {
        animation-delay: 3400ms;
      }
      &.puzzle-anim-35 {
        animation-delay: 3500ms;
      }
      &.puzzle-anim-36 {
        animation-delay: 3600ms;
      }
      &.puzzle-anim-37 {
        animation-delay: 3700ms;
      }
      &.puzzle-anim-38 {
        animation-delay: 3800ms;
      }
      &.puzzle-anim-39 {
        animation-delay: 3900ms;
      }
      &.puzzle-anim-40 {
        animation-delay: 4000ms;
      }
      &.puzzle-anim-41 {
        animation-delay: 4100ms;
      }
      &.puzzle-anim-42 {
        animation-delay: 4200ms;
      }
      &.puzzle-anim-43 {
        animation-delay: 4300ms;
      }
      &.puzzle-anim-44 {
        animation-delay: 4400ms;
      }
      &.puzzle-anim-45 {
        animation-delay: 4500ms;
      }
      &.puzzle-anim-46 {
        animation-delay: 4600ms;
      }
      &.puzzle-anim-47 {
        animation-delay: 4700ms;
      }
      &.puzzle-anim-48 {
        animation-delay: 4800ms;
      }
      &.puzzle-anim-49 {
        animation-delay: 4900ms;
      }
      &.puzzle-anim-50 {
        animation-delay: 5000ms;
      }
    }
  }

}
