
.navbar {
  background-color: #563d7c;

  .navbar-collapse {
    transition: ease-in 0.5s;
    max-height: 0;
    display: block;
    &.show {
      max-height: 500px;
    }
    .navbar-nav {
      display: none;
    }
    &.show .navbar-nav {
      display: block;
    }
  }

  .dropdown-item {
    font-size: 13px;
    padding: 0 0.5rem;
  }
}

@media (min-width: 768px) {
  .navbar {
    .navbar-collapse {
      transition: ease-in 0.5s;
      max-height: none;
      display: block;
      .navbar-nav {
        display: flex;
      }
    }
  }
}