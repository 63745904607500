td.puzzle-cell {
  width: 20px;
  height: 20px;
  cursor: 'pointer';
  touch-action: 'none';
  &.puzzle-cell-on {
    background: #000;
  }
  &.puzzle-cell-off {
    background: url("/x.png") no-repeat center;
  }
}
